import React, { useEffect, useState } from 'react'
// 
import '../STYLESHEETS/Services.css'
// 
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/May52023/nail1.jpeg'
import img2 from '../PHOTOS/MAIN/May52023/nail4.jpeg'
import img3 from '../PHOTOS/MAIN/May52023/nail3.jpeg'
import img4 from '../PHOTOS/MAIN/May52023/nail2.jpeg'
import img5 from '../PHOTOS/MAIN/May52023/nail5.jpeg'
import img6 from '../PHOTOS/MAIN/May52023/nail6.jpeg'
import img7 from '../PHOTOS/MAIN/May52023/nail7.jpeg'
import img8 from '../PHOTOS/MAIN/May52023/nail6.jpg'
import img9 from '../PHOTOS/MAIN/May52023/nail8.jpeg'
import img10 from '../PHOTOS/MAIN/May52023/nail9.jpeg'
//
import Navigation from './UTILITIES/Navigation'
import { RxHamburgerMenu } from 'react-icons/rx'
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs'
import Footer from './UTILITIES/Footer'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_mainURL } from '../Constants'
// 

export default function Services() {

    const [serviceID, setServiceID] = useState(-1)
    const services = [
        {
            id: 0,
            Name: "Manicure", Img: img1,
            Desc: "A classic nail treatment that includes nail trimming, shaping, cuticle care, massage, and polish application.",
            Details: "Classic Manicure, Gel manicure/No chip, 2 x 10 Signature Manicure, Deluxe Spa Manicure, Polish Change"
        },
        {
            id: 1,
            Name: "Acrylic", Img: img2,
            Desc: "Acrylic nails are artificial nail extensions made by combining a liquid and powder to form a durable coating over natural nails.",
            Details: "Regular Polish, Gel Polsh, Ombre, Pink and White, Color Powder"
        },
        {
            id: 2,
            Name: "Pedicure", Img: img3,
            Desc: "Pedicure is a cosmetic foot treatment that involves trimming, shaping, buffing, and polishing toenails, as well as exfoliating and moisturizing the feet.",
            Details: "Classic Pedicure, Gel Pedicure/No chip, 2 x 10 Signature Pedicure, Mango Tango Pedicure, Cucumber Fiesta Pedicure, Green Tea Detox, Polish Change"
        },
        {
            id: 3,
            Name: "Gel Extension", Img: img4,
            Desc: "Gel extension nail service is a type of artificial nail application where a gel-based product is sculpted onto the natural nail or nail extension for added length and durability.",
            Details: "Apres Gel X, Acrylic/Gel/Dip Removal"
        },
        {
            id: 4,
            Name: "Gel Polish", Img: img5,
            Desc: "Gel polish nail service is a type of nail polish that is cured under a UV or LED lamp for a longer-lasting and more durable finish compared to regular nail polish.",
            Details: "Color Change Only (Hands/Feet), Gel Polish Removal"
        },
        {
            id: 5,
            Name: "Dipping", Img: img6,
            Desc: "Nail dipping service is a type of nail enhancement technique that involves applying a resin-based glue onto the nails and dipping them into a colored acrylic powder for added strength and color.",
            Details: "Overlay Color, Ombre, French, Nail Tips, Dip Removal"
        },
        {
            id: 6,
            Name: "Kids Nails", Img: img8,
            Desc: "A nail service for kids is a specialized type of manicure or pedicure, which includes mild trimming, shaping, and coloring of nails using child-safe and non-toxic nail polish.",
            Details: "Kiddie Manicure, Kiddie Pedicure, Gel Polish"
        },
        {
            id: 7,
            Name: "Waxing", Img: img7,
            Desc: "Waxing service is a method of hair removal that involves the application of warm wax to the skin, which is then removed with a cloth or paper strip, leaving the skin smooth and hair-free.",
            Details: "Eyebrow, Lip, Chin, Sideburn, Full Face, Chest, Full Back, Half Arms, Full Arms, Underarms, Half Legs, Full Legs, Bikini, Brazilian"
        },
        {
            id: 8,
            Name: "Massage", Img: img9,
            Desc: "Massage service is a therapeutic technique that involves the manipulation of muscles, soft tissues, and joints to alleviate tension, pain, and promote relaxation.",
            Details: "Feet Massage, Shoulder Massage, Chair Massage, COMBO(Feet and Shoulder"
        },
        {
            id: 9,
            Name: "Add-On Services", Img: img10,
            Desc: "These add-on services can be applied to the services already provided.",
            Details: "Nail Repair, French, Design, Buff Shine, Callus Treatment, Paraffin Treatment, Collagen Gloves"
        },
    ]

    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Services", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Services | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Services | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="services font1">
                <h1 className='page-title'>Services</h1>
                <div className='services-split'>
                    <div className='services-right'>
                        <div className='divider'></div>
                        <p>Nail salons and spas offer a variety of services focused on nail care and relaxation. These services can range from basic manicures and pedicures to more advanced treatments like acrylics and gels. With the goal of providing clients with a luxurious and rejuvenating experience, nail salons and spas aim to help individuals look and feel their best.</p>
                    </div>
                    <div className='services-left'>
                        {
                            services.map((service, i) => {
                                return (
                                    <div key={i} className="service-block">
                                        <h2 className='color4'>{service.Name}</h2>
                                        <img src={service.Img} className="service-img" />
                                        <p className='service-block-desc'>{service.Desc}</p>
                                        {
                                            service.id == serviceID ?
                                                <p className='service-block-deets border-top1'>{service.Details}</p> : <p></p>
                                        }
                                        {
                                            service.id == serviceID ?
                                                <button className='service-btn no-border color1 bg8' onClick={() => { setServiceID(-1) }}>Show Less</button> :
                                                <button className='service-btn no-border color2 bg7' onClick={() => { setServiceID(service.id) }}>Show More</button>
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
