import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';

// 
import '../STYLESHEETS/Home4.css'
import NailPolish from '../PHOTOS/MAIN/nail-polish.png';
import specialImg from '../PHOTOS/MAIN/special.png'

// 
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/home-nail1.jpeg'
import img2 from '../PHOTOS/shop2.jpg'
// 
import { Link, useNavigate } from 'react-router-dom'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_mainURL } from '../Constants';
import { AiFillCalendar } from 'react-icons/ai'
import { HiMagnifyingGlass, HiXMark } from 'react-icons/hi2'

export default function Home() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Home", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>2x10 Nails & Spa | Nail Salon in Chicago | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content="2x10 Nails & Spa | Nail Salon in Chicago | Manicures, Pedicures, Waxing" />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            {
                showModal ?
                    <div className='black-out'>
                        <HiXMark className="close-icon color5" onClick={() => { setShowModal(false) }} />
                        <img src={specialImg} className='modal-img' />
                    </div> : <div></div>
            }
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon color4' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="home font1">
                <div className='home4-wrap'>
                    <div className='home4-left'>
                        <img src={img1} className='home4-img img-left' />
                        <div className='home4-left-text'>
                            <h1 className='home4-head color4'>2 x 10 Nails & Spa</h1><br />
                            <p className='home4-cap font2'>Experience high-end nail care services with top-quality products and skilled technicians. Choose from classic, gel, acrylic, dip powder nails, and nail art designs. Visit us today!</p>
                            <div className='home4-split'>
                                <button onClick={() => { navigate('/schedule') }} className='no-border bg7 color2'><p>Schedule Appointment Now</p> <AiFillCalendar className='home4-icon' /></button>
                                <button onClick={() => { navigate('/services') }} className='no-border bg8 color3'><p>Check out our services</p><img src={NailPolish} className='home4-nail' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='home4-right'>
                        <img src={img2} className='home4-img img-right' />

                    </div>
                </div>

            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
