import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Locations.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { c_mainURL } from '../Constants'
import { Helmet } from 'react-helmet'
// 

export default function History() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Locations", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Locations | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Locations | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="locations font1">
                <h1 className='page-title'>Locations & Hours</h1>
                <div className='location-pair'>
                    <div className='location-pair-split'>
                        <div>
                            <h1 className='color4'>Chicago Location<br/>2 x 10 Nails & Spa</h1>
                            <h2>2112 W Roscoe St<br/>Chicago IL<br/>60618</h2>

                        </div>
                        <div>
                            <h3 className='color4'>Hours:</h3>
                            <ul>
                                <li>Monday: 10:00 AM - 8:00 PM</li>
                                <li>Tuesday: 10:00 AM - 8:00 PM</li>
                                <li>Wednesday: 10:00 AM - 8:00 PM</li>
                                <li>Thursday: 10:00 AM - 8:00 PM</li>
                                <li>Friday: 10:00 AM - 8:00 PM</li>
                                <li>Saturday: 10:00 AM - 7:00 PM</li>
                                <li>Sunday: 10:00 AM - 6:00 PM</li>
                            </ul>
                        </div>
                    </div>
                    <div className='locations-wrap'>
                        <div className='locations-map'>
                            <iframe className="map" id="gmap_canvas" src="https://maps.google.com/maps?q=2%20x%2010%20Nails%20Chicago%20Roscoe&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                            </iframe>
                        </div>
                    </div>
                </div>
                
            </div>
            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
