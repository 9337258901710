import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Gallery.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link, useNavigate } from 'react-router-dom'
// 
import img1 from '../PHOTOS/MAIN/nails-gallery1.jpeg'
import img2 from '../PHOTOS/MAIN/May52023/nail2.jpeg'
import img3 from '../PHOTOS/MAIN/nails-gallery3.jpeg'
import img4 from '../PHOTOS/MAIN/May52023/nail3.jpeg'
import img5 from '../PHOTOS/MAIN/May52023/nail1.jpeg'
import img6 from '../PHOTOS/MAIN/May52023/nail5.jpeg'
import img7 from '../PHOTOS/MAIN/nails-gallery7.jpeg'
import img8 from '../PHOTOS/MAIN/May52023/nail7.jpeg'
import img9 from '../PHOTOS/MAIN/May52023/nail9.jpeg'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_mainURL } from '../Constants'
import { useDispatch } from 'react-redux'
import { setPhotoState } from '../REDUX/SLICES/PhotoSlice'

export default function Gallery() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    const images = [
        {
            Img: img1,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img2,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img3,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img4,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img5,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img6,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img7,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img8,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
        {
            Img: img9,
            Alt: "2 x 10 Nails & Spa Gel, Powder, Acrylic",
            Lens: "",
            Title: "",
            Desc: ""
        },
    ]


    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Gallery", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Gallery | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Gallery | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="gallery font1">
                <h1 className='page-title'>Nails & Spa Gallery</h1>
                <br/>
                <div className='gallery-wrap'>
                    {
                        images.map((img, i) => {
                            return (
                                <img key={i} src={img.Img} alt={`${img.Alt}`} className="gallery-img" onClick={() => {
                                    dispatch(setPhotoState(img));
                                    navigate('/gallery-photo')
                                }} />
                            )
                        })
                    }
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
            <style>
            </style>
        </div>
    )
}
