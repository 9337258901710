import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Blog.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link, useNavigate } from 'react-router-dom'
import img1 from '../PHOTOS/MAIN/nails-gallery1.jpeg'
import img2 from '../PHOTOS/MAIN/nails-gallery1.jpeg'
import img3 from '../PHOTOS/MAIN/nails-gallery6.jpeg'
import img4 from '../PHOTOS/MAIN/nails-about2.jpeg'
import img5 from '../PHOTOS/MAIN/nails-about3.jpeg'
// 
import { setBlogPostState } from '../REDUX/SLICES/BlogPostSlice'
import { useDispatch } from 'react-redux'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_mainURL } from '../Constants'

export default function Blog() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const testBlogs = [
        {
            id: 0,
            Date: "April 29, 2023",
            Title: "From Classic to Trendy: Exploring Different Types of Nail Manicures",
            Desc: " Nail manicures have become a go-to for anyone looking to pamper themselves and have fabulous-looking nails. With so many different types of nail manicures available, it can be overwhelming to choose the right one. In this blog post, we'll take a look at the different types of nail manicures and their benefits.",
            ImgPath: img1,
            Author: "Anna",
            Tags: "Nails, Manicures, Polish", HTML: <div className='blogpost-wrap'>
                <p>
                    Nail manicures have become a go-to for anyone looking to pamper themselves and have fabulous-looking nails. With so many different types of nail manicures available, it can be overwhelming to choose the right one. In this blog post, we'll take a look at the different types of nail manicures and their benefits.
                </p>
                <img src={img2} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <h3>Basic Manicure</h3>
                <p>
                    This is the most common type of nail manicure. It involves shaping and filing the nails, pushing back the cuticles, and finishing with a nail polish of your choice. A basic manicure is perfect for anyone who wants to keep their nails looking clean and polished.
                </p>
                <br />
                <h3>French Manicure</h3>
                <p>
                    The French manicure is a classic look that involves painting the tips of the nails white and the rest of the nail bed in a sheer nude color. This type of manicure is perfect for anyone looking for a timeless, sophisticated look.
                </p>
                <br />
                <h3>Gel Manicure</h3>
                <p>
                    A gel manicure is a long-lasting type of manicure that involves curing gel nail polish under a UV light. This type of manicure can last up to three weeks without chipping or peeling, making it perfect for anyone who doesn't have time to constantly maintain their nails.
                </p>
                <br />
                <h3>Shellac Manicure</h3>
                <p>
                    A shellac manicure is similar to a gel manicure but uses a different type of polish. Shellac polish is a hybrid of gel and traditional nail polish that is cured under a UV light. This type of manicure can last up to two weeks without chipping or peeling.
                </p>
                <br />
                <h3>Acrylic Manicure</h3>
                <p>
                    An acrylic manicure involves adding a fake nail extension to your natural nails using acrylic powder and liquid. The fake nails are then shaped and filed to your desired length and shape. Acrylic manicures are perfect for anyone looking to add length or strength to their nails.
                </p>
                <br />
                <h3>Dip Powder Manicure</h3>
                <p>
                    A dip powder manicure involves dipping your nails into a colored powder that hardens into a long-lasting manicure. This type of manicure can last up to three weeks without chipping or peeling and doesn't require UV light like gel and shellac manicures.
                </p>
                <br />
                <img src={img3} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <p>
                    In conclusion, there are many different types of nail manicures to choose from, each with its own benefits. Whether you're looking for a basic, classic look or a long-lasting manicure, there's a type of manicure that's perfect for you. Consider trying out different types of manicures to find the perfect one for your lifestyle and personal style.
                </p>
            </div>
        },
        {
            id: 1,
            Date: "April 29, 2023",
            Title: "5 Reasons Why 2x10 Nails & Spa is the Perfect Choice for Your Special Occasions",
            Desc: "Special occasions like weddings, graduations, and proms call for exceptional beauty and self-care services. That's why 2x10 Nails & Spa is the perfect choice for all your pampering needs. Here are five reasons why:",
            ImgPath: img4,
            Author: "Anna",
            Tags: "Nails, Manicures, 2 x 10 Nails & Spa", HTML: <div className='blogpost-wrap'>
                <p>
                    Special occasions like weddings, graduations, and proms call for exceptional beauty and self-care services. That's why 2x10 Nails & Spa is the perfect choice for all your pampering needs. Here are five reasons why:
                </p>
                <img src={img4} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <h3>1. Experienced Technicians:</h3>
                <p>
                    Our team of experienced technicians is dedicated to providing you with exceptional customer service. They are skilled in creating elegant and sophisticated nail designs that complement any occasion.
                </p>
                <br />
                <h3>2. Wide Range of Services</h3>
                <p>
                    We offer a wide range of nail care and spa services that are perfect for weddings, graduations, and other special events. From manicures and pedicures to facials and massages, we have everything you need to look and feel your best.
                </p>
                <br />
                <h3>3. Customized Packages</h3>
                <p>
                    We understand that every occasion is unique. That's why we offer customized packages that can be tailored to your specific needs and preferences. Whether you're looking for a simple touch-up or a full spa day, we've got you covered.
                </p>
                <br />
                <h3>4. High-Quality Products:</h3>
                <p>
                    At 2x10 Nails & Spa, we use only the highest quality products to ensure the best possible results for your nails and skin. We work with leading brands in the industry to bring you the best in beauty and skincare.
                </p>
                <br />
                <h3>5. Hygiene and Safety</h3>
                <p>
                    We take hygiene and safety seriously, especially during special occasions when you want everything to be perfect. Our salon is clean and sanitized at all times, and we follow strict industry guidelines to ensure a safe and healthy environment for our clients.
                </p>
                <br />
                <img src={img5} className="blogpost-img" alt='Lorem ipsum dolor sit amet, consectetur adipiscing elit.' />
                <p>
                    In conclusion, 2x10 Nails & Spa is the perfect choice for all your beauty and self-care needs for special occasions. With our experienced technicians, wide range of services, customized packages, high-quality products, and strict hygiene and safety standards, you can trust us to make your special day even more memorable. Book an appointment with us today and let us help you look and feel your best!
                </p>
            </div>
        },
    ]
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }
    const [firstBlog, setFirstBlog] = useState({})
    const [tempBlogs, setTempBlogs] = useState([])

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        testBlogs.reverse()
        setFirstBlog(testBlogs[0])
        setTempBlogs(testBlogs)
        firebaseGetPageViews({ Name: "Blog", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Blog | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Blog | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="blog font1">
                <h1 className='page-title'>Blog</h1>
                <div className='blog-wrap'>
                    <div className='blog-left'>
                        <div className='first-blog-block' onClick={() => {
                            dispatch(setBlogPostState(firstBlog));
                            navigate('/blogpost')
                        }}>
                            <img src={firstBlog.ImgPath} className="first-blog-img" />
                            <div className='first-blog-text'>
                                <p className='first-blog-date'>{firstBlog.Date}</p>
                                <h1 className='first-blog-title color4'>{firstBlog.Title}</h1>
                                <p className='first-blog-desc'>{firstBlog.Desc}</p>
                                <div className='first-blog-tags'>
                                    {/* {
                                        firstBlog.Tags.length > 0 ?
                                            firstBlog.Tags.split(",").map((tag, j) => {
                                                return (
                                                    <p key={j} className='first-blog-tag border2'>{tag}</p>
                                                )
                                            }) : <div></div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='blog-right'>
                        {
                            tempBlogs.map((blog, i) => {
                                if (i > 0) {
                                    return (
                                        <div key={i} className='blog-block' onClick={() => {
                                            dispatch(setBlogPostState(blog));
                                            navigate('/blogpost')
                                        }}>
                                            <img src={blog.ImgPath} className="blog-img" />
                                            <div className='blog-text'>
                                                <p className='blog-date'>{blog.Date}</p>
                                                <h1 className='blog-title color4'>{blog.Title}</h1>
                                                <p className='blog-desc'>{blog.Desc}</p>
                                                <div className='blog-tags'>
                                                    {
                                                        blog.Tags.split(",").map((tag, j) => {
                                                            return (
                                                                <p key={j} className='blog-tag border2'>{tag}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
