import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/About.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/MAIN/nails-about1.jpeg'
import img2 from '../PHOTOS/MAIN/nails-about2.jpeg'
import img3 from '../PHOTOS/MAIN/nails-about3.jpeg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_mainURL } from '../Constants'

export default function About() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "About", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>About | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`About | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="about font1">
                <h1 className='page-title'>Nail Care and Relaxation</h1>
                <div className='about-wrap'>
                    <div className='about-section'>
                        <div>
                            <h2 className='about-sub'></h2>
                            <img src={img1} className="about-img" />
                        </div>
                        <div className='about-text'>
                            <p>
                                Looking for a luxurious and relaxing nail salon in Chicago? Look no further than 2 x 10 Nails & Spa. Our team of experienced technicians is dedicated to providing you with exceptional customer service, ensuring that you leave our salon feeling pampered and refreshed.
                            </p>
                            <p>
                                We offer a wide range of nail care services, including manicures, pedicures, acrylics, gels, and more. Equipped with the latest technology and highest quality products, our salon guarantees the best possible results for your nails.
                            </p>
                        </div>
                    </div>
                    <div className='about-section'>
                        <div>
                            <h2 className='about-sub color4'>Self-Care at 2 x 10 Nails & Spa</h2>
                            <img src={img2} className="about-img" />
                        </div>
                        <div className='about-text'>
                            <p>
                                At 2 x 10 Nails & Spa, we understand the importance of self-care for a healthy lifestyle. That's why our salon provides a warm and welcoming environment for you to unwind and indulge in some much-needed pampering. Our relaxing atmosphere offers the perfect escape from the stresses of everyday life. Our team takes great pride in our work and is committed to providing you with a luxurious and rejuvenating nail salon experience.
                            </p>
                        </div>
                    </div>
                    <div className='about-section'>
                        <div>
                            <h2 className='about-sub color4'>Hygiene and Safety</h2>
                            <img src={img3} className="about-img" />
                        </div>
                        <div className='about-text'>
                            <p>
                                At 2 x 10 Nails & Spa, we understand the importance of self-care for a healthy lifestyle. That's why our salon provides a warm and welcoming environment for you to unwind and indulge in some much-needed pampering. Our relaxing atmosphere offers the perfect escape from the stresses of everyday life. Our team takes great pride in our work and is committed to providing you with a luxurious and rejuvenating nail salon experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
