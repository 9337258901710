import React, { useEffect, useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { HiXMark } from 'react-icons/hi2'
// 
import '../STYLESHEETS/Schedule.css'
import logo from '../PHOTOS/stock.png'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import Modal from './UTILITIES/Modal'
import { Link } from 'react-router-dom'
import { createScheduledEvent, firebaseGetPageViews, getEventTypes, getScheduledEvents } from '../FIREBASE/firebase'
import { useDispatch, useSelector } from 'react-redux'

import { getDayOfWeek_Word, getMonth_Word, removeDuplicatesByProperty } from '../Global'
import { setLoadingState } from '../REDUX/SLICES/LoadingSlice'
import { setSuccessState } from '../REDUX/SLICES/SuccessSlice'
import { setFailureState } from '../REDUX/SLICES/FailureSlice'
import { c_businessName, c_mainURL, emailjs_fromEmail, emailjs_schedule_message } from '../Constants'
import { setEventTypesState } from '../REDUX/SLICES/EventTypesSlice'
import { Helmet } from 'react-helmet'

export default function Schedule() {
    const eventTypes = useSelector((state) => state.eventTypes.value)
    const scheduledEvents = useSelector((state) => state.scheduledEvents.value)
    const dispatch = useDispatch()


    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }

    const [types, setTypes] = useState([])
    const [slots, setSlots] = useState([])
    const [workers, setWorkers] = useState([])
    const [chosenDate, setChosenDate] = useState("")
    const [chosenService, setChosenService] = useState("")
    const [chosenWorker, setChosenWorker] = useState("")
    const [chosenSlotInfo, setChosenSlotInfo] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [chosenPrice, setChosenPrice] = useState("")

    // 
    const [categories, setCategories] = useState([])
    const [chosenCategory, setChosenCategory] = useState("")
    const [chosenTypeHere, setChosenType] = useState("")

    const getDate = () => {
        setSlots([])
        setWorkers([])
        setCategories([])
        setChosenWorker("")
        setChosenCategory("")
        var date = document.querySelector("#dpDay").value
        var dateEnd = new Date(date.replace(/-/g, '\/'))
        dateEnd = parseInt(dateEnd.getTime() / 1000) + 86400
        dateEnd = new Date(dateEnd * 1000)
        date = new Date(date.replace(/-/g, '\/'))
        setChosenDate(date)
        getScheduledEvents(dispatch, date, dateEnd)
            .then(() => {
                const dayOfWeek = new Date(date).getDay()
                const dayOfWeekStr = getDayOfWeek_Word(dayOfWeek)
                var temp = eventTypes.filter(x => x.DOW.includes(dayOfWeekStr))
                setTypes(temp)
                giveMeCategories(temp)
            })
    }
    const getAvailableTimes = () => {
        const chosenDate = document.querySelector("#dpDay").value.replace(/-/g, '/');
        
        if (chosenDate !== "") {
            const type = chosenTypeHere; // Replace with your actual 'chosenTypeHere'
            const duration = type.Duration; // Assuming 'type' is defined
            
            const openHour = 10;
            const openMinute = 0;
            const closeHour = 20;
            const closeMinute = 30;
    
            const tempSlots = [];
    
            for (let hour = openHour; hour < closeHour; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const slotHour = hour + Math.floor((minute + openMinute) / 60);
                    const slotMinute = (minute + openMinute) % 60;
                    const slot = `${slotHour > 12 ? slotHour - 12 : slotHour}:${slotMinute < 10 ? "0" : ""}${slotMinute} ${hour >= 12 ? "PM" : "AM"}`;
                    tempSlots.push(slot);
                }
            }
    
            // Set your slots state with the generated time slots.
            setSlots(tempSlots);
        }
    };
    
    const chooseSlot = (slot) => {
        const hours = parseInt(slot.split(":")[0])
        const minutes = parseInt(slot.split(":")[1])

        const date = chosenDate.setHours(hours, minutes, 0, 0)
        const dayOfWeek = getDayOfWeek_Word(chosenDate.getDay())
        const month = getMonth_Word(new Date(date).getMonth() + 1)
        const day = new Date(date).getDate()
        const year = new Date(date).getFullYear()
        const slotInfo = {
            Slot: slot,
            Date: date,
            DayOfWeek: dayOfWeek,
            Service: chosenService.Type,
            Duration: chosenService.Duration,
            Price: chosenService.Price,
            Month: month,
            Day: day,
            Year: year,
            Worker: chosenWorker
        }
        console.log(slotInfo)
        setChosenSlotInfo(slotInfo)
        setShowDetails(true)
    }
    const sendConfirmation = () => {
        dispatch(setLoadingState(true))
        const name = document.querySelector('#tbName').value
        const email = document.querySelector('#tbEmail').value
        const phone = document.querySelector('#tbPhone').value
        const start = chosenSlotInfo.Date / 1000
        const end = parseInt(chosenSlotInfo.Date / 1000) + (parseInt(chosenService.Duration) * 60)
        console.log(chosenService.Duration)
        if (name != "" && email != "") {
            const args = {
                Name: name,
                End: end,
                Start: start,
                Email: email,
                Phone: phone,
                Type: chosenSlotInfo.Service,
                Worker: chosenWorker
            }
            var templateParams = {
                from_name: c_businessName,
                to_name: name,
                date_string: `${chosenSlotInfo.DayOfWeek}, ${chosenSlotInfo.Month} ${chosenSlotInfo.Day} ${chosenSlotInfo.Year} @ ${chosenSlotInfo.Slot} with ${chosenSlotInfo.Worker}`,
                to_email: email,
                message: emailjs_schedule_message,
                from_email: emailjs_fromEmail,
                reply_to: emailjs_fromEmail
            };
            const myParams = {
                from_name: c_businessName,
                to_name: name,
                date_string: `${chosenSlotInfo.DayOfWeek}, ${chosenSlotInfo.Month} ${chosenSlotInfo.Day} ${chosenSlotInfo.Year} @ ${chosenSlotInfo.Slot} with ${chosenSlotInfo.Worker}`,
                to_email: emailjs_fromEmail,
                message: "Confirmation has been sent to the customer.",
                from_email: email,
                reply_to: email
            }

            createScheduledEvent(args, templateParams, myParams)
                .then(() => {
                    dispatch(setLoadingState(false))
                    dispatch(setSuccessState(true))
                    setTimeout(() => {
                        dispatch(setSuccessState(false))
                        setShowModal(true)
                        setTimeout(() => {
                            setShowModal(false)
                            window.location.reload(false);
                        }, 4000);
                    }, 3000);
                })
                .catch((error) => {
                    console.log(error)
                    dispatch(setFailureState(true))
                    dispatch(setLoadingState(false))
                    setTimeout(() => {
                        dispatch(setFailureState(false))
                    }, 3000);
                })

        } else {
            dispatch(setLoadingState(false))
        }

    }
    const selectWorkers = (thing) => {
        setChosenType(thing)
        for (var i = 0; i < types.length; i = i + 1) {
            if (types[i].Type == thing) {
                console.log(types[i].Workers.split(','))
                console.log(types[i])
                const workers = types[i].Workers.split(',')
                setWorkers(workers)
                setChosenService(types[i])
            }
        }
        
    }
    const selectWorker = () => {
        const worker = document.querySelector('#ddWorker').value
        setChosenWorker(worker)
        setSlots([])
    }
    // 
    const giveMeCategories = (types) => {
        const tempCategs = []
        for (var i = 0; i < types.length; i = i + 1) {
            tempCategs.push(types[i].Category)
        }
        const uniqueArray = tempCategs.filter((value, index, self) => self.indexOf(value) === index);
        console.log(uniqueArray)
        setCategories(uniqueArray)
    }
    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Schedule", Views: 0 })
        getEventTypes(dispatch)
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Schedule | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Schedule | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="schedule font1">
                <h1 className='page-title'>Schedule</h1>
                <br />
                {
                    showDetails ?
                        <div className='schedule-rev font1 color1'>
                            <div className='schedule-rev-wrap bg2'>
                                <div className='separate'>
                                    <h1>Before you confirm...</h1>
                                    <HiXMark onClick={() => {
                                        setShowDetails(false)
                                    }} className="schedule-rev-icon" />
                                </div>
                                <p>We would like to get some information from you so that we know who is scheduling and where to send the confirmation to.</p>
                                <br />
                                <h2 className='schedule-rev-date'>{chosenSlotInfo.DayOfWeek}, {chosenSlotInfo.Month} {chosenSlotInfo.Day} {chosenSlotInfo.Year}<br /> @ {chosenSlotInfo.Slot}</h2>
                                {/* MINUTES WAS REMOVED FROM HERE */}
                                {/* <h3 className='schedule-rev-details color4 bold'>{chosenSlotInfo.Service}- {chosenPrice.includes("$") ? "" : "$"}{chosenPrice}</h3>
                                <h3 className='schedule-rev-details color4'>By {chosenSlotInfo.Worker}</h3> */}
                                <br />
                                <div className='schedule-rev-pair'>
                                    <label>Name</label>
                                    <input className='schedule-input border2' id="tbName" type="text" placeholder='John Doe' />
                                </div>
                                <div className='schedule-rev-pair'>
                                    <label>Email</label>
                                    <input className='schedule-input border2' id="tbEmail" type="email" placeholder='jdoe@happy.com' />
                                </div>
                                <div className='schedule-rev-pair'>
                                    <label>Phone</label>
                                    <input className='schedule-input border2' id="tbPhone" type="email" placeholder='123-456-7890' />
                                </div>
                                <button onClick={() => { sendConfirmation() }} className='schedule-rev-btn no-border bg7 color2'>Confirm Date/Time</button>
                            </div>
                        </div> : <div></div>
                }
                {
                    showModal ?
                        <Modal heading="Appointment Confirmed" text="An email will be sent shortly with all details of your appointment. Thank you." /> : <div></div>
                }
                <div className='schedule-wrap'>
                    <div className='schedule-left'>
                        <div>
                            <h2 className='schedule-pick color4'>Pick a day:</h2>
                            <input type="date" className="schedule-pick-date border2" id="dpDay" onChange={getDate} />
                        </div>

                        {/* {
                            chosenDate != "" ?
                                <div>
                                    <h2 className='schedule-pick color4'>Pick a Service:</h2>
                                    <select id="ddType" className='schedule-pick-dd border2' onChange={selectWorkers}>
                                        <option>Select one</option>
                                        {
                                            types.map((type, i) => {
                                                return (
                                                    // MINUTES WAS REMOVED FROM HERE
                                                    <option key={i}>{type.Category} ~ {type.Type}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> : <div></div>
                        } */}

                        {
                            chosenService.Type != undefined && chosenWorker != 'Select one' && chosenWorker != "" ?
                                <p className='type-desc bg8 color1'><h3>{chosenService.Category} - {chosenService.Type}</h3><br />{chosenService.Desc}</p> : <div></div>
                        }

                    </div>
                    <div className='schedule-right'>
                        <div>
                            {
                                chosenDate != "" && slots.length == 0 ?
                                    <div>
                                        <h2 className='schedule-pick color4'>What kind of service:</h2>
                                        <div className='sched-categories'>
                                            {
                                                categories.map((cate, i) => {
                                                    return (
                                                        <div key={i} className='sched-cate'>
                                                            <button onClick={() => { setChosenCategory(cate) }} className={`border2 ${chosenCategory == cate ? 'bg7 color2' : 'no-bg color4'}`}>{cate}</button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div> : <div></div>
                            }
                        </div>
                        <div>
                            {
                                chosenDate != "" && slots.length == 0 && chosenCategory != "" ?
                                    <div>
                                        <h2 className='schedule-pick color4'>Pick a Service:</h2>
                                        <div className='type-btns'>
                                            {
                                                types.map((type, i) => {
                                                    if (type.Category == chosenCategory) {
                                                        return (
                                                            // MINUTES WAS REMOVED FROM HERE
                                                            <button key={i} className={`border2 ${chosenTypeHere == type.Type ? 'bg7 color2' : 'no-bg color4'} type-btn`} onClick={() => { selectWorkers(type.Type) }}>{type.Type}</button>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div> : <div></div>
                            }
                        </div>
                        {/* <div>
                            {
                                workers.length > 0 != "" && slots.length == 0 ?
                                    <div>
                                        <h2 className='schedule-pick color4'>Workers:</h2>
                                        <select id="ddWorker" className='schedule-pick-dd border2' onChange={selectWorker}>
                                            <option>Select one</option>
                                            {
                                                workers.map((worker, i) => {
                                                    return (
                                                        <option key={i}>{worker}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div> : <div></div>
                            }
                        </div> */}
                        <div>
                            {
                                slots.length == 0 && chosenService != "" ?
                                    <button className='schedule-pick-btn bg7 color2 no-border' onClick={getAvailableTimes}>Get Available Times</button> : <div></div>
                            }
                        </div>
                        {
                            slots.length > 0 ?
                                <div>
                                    <h2 className='schedule-pick color4'>Pick a Slot:</h2>
                                    <div className='schedule-right-slots'>
                                        {
                                            slots.map((slot, i) => {
                                                return (
                                                    <div className='schedule-slot' key={i}>
                                                        <button onClick={() => { chooseSlot(slot); }} className='border2 no-bg color4'>{slot}</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div> : <div></div>
                        }
                    </div>
                </div>
            </div>
            <br /><br />
            <br /><br />
            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
