import React, { useEffect } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
// 
import '../STYLESHEETS/Reviews.css'
import logo from '../PHOTOS/stock.png'
import img1 from '../PHOTOS/shop.jpg'
import Footer from './UTILITIES/Footer'
import Navigation from './UTILITIES/Navigation'
import { Link } from 'react-router-dom'
import { firebaseGetPageViews } from '../FIREBASE/firebase'
import { Helmet } from 'react-helmet'
import { c_mainURL } from '../Constants'

export default function Reviews() {
    function openNav() {
        if (window.innerWidth < 600) {
            document.querySelector(".nav-body").style.width = "100vw";
        } else if (window.innerWidth < 800) {
            document.querySelector(".nav-body").style.width = "50vw";
        } else if (window.innerWidth < 1000) {
            document.querySelector(".nav-body").style.width = "40vw";
        } else if (window.innerWidth < 1200) {
            document.querySelector(".nav-body").style.width = "35vw";
        } else {
            document.querySelector(".nav-body").style.width = "20vw";
        }
    }
    function closeNav() {
        document.querySelector(".nav-body").style.width = "0";
    }



    useEffect(() => {
        closeNav()
        window.scrollTo(0, 0)
        firebaseGetPageViews({ Name: "Reviews", Views: 0 })
    }, [])
    return (
        <div className='main'>
            <Helmet>
                <title>Reviews | 2x10 Nails & Spa | Manicures, Pedicures, Waxing</title>
                <meta name="description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta name="keywords" content="2x10 Nails & Spa, nail salon, manicure, pedicure, waxing, Chicago, Illinois, nail care" />
                <meta name="author" content="Happy Code Websites & Apps" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${c_mainURL}`} />
                <meta property="og:title" content={`Reviews | 2x10 Nails & Spa | Manicures, Pedicures, Waxing`} />
                <meta property="og:description" content="Visit 2x10 Nails & Spa for the best nail care services in Chicago, Illinois. Our nail salon offers professional manicures, pedicures, and waxing services. Book your appointment today!" />
                <meta property="og:url" content={`${c_mainURL}`} />
                <meta property="og:image" content={`${c_mainURL}/src/PHOTOS/stock.png`} />
                <meta property="og:site_name" content="2x10 Nails & Spa" />
                <meta property="og:type" content="website" />
            </Helmet>
            {/* NAGIVATION */}
            <Navigation />
            <div className='top'>
                <Link to="/"><img src={logo} /></Link>
                <RxHamburgerMenu className='top-icon' onClick={openNav} />
            </div>
            {/* BODY */}
            <div className="padding font1">
                <h1 className='page-title'>See what others are saying.</h1>
                <br />
                <div className='reviews'>
                    <div className='review border-green'>
                        <h1 className='color1'>"I had the best experience at 2 x 10 Nails & Spa! The staff was so friendly and attentive to my needs. My nails have never looked better. Highly recommend!"</h1>
                        <br />
                        <h2>Rachel Smith</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                    <div className='review border-green'>
                        <h1 className='color1'>"I always leave 2 x 10 Nails & Spa feeling refreshed and pampered. Their attention to detail is top-notch and the salon is always clean and welcoming."</h1>
                        <br />
                        <h2>Emily Lee</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                    <div className='review border-green'>
                        <h1 className='color1'>"I had a wonderful time at 2 x 10 Nails & Spa. The atmosphere was so relaxing and my pedicure was amazing. Can't wait to go back!"</h1>
                        <br />
                        <h2>Samantha Williams</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                    <div className='review border-green'>
                        <h1 className='color1'>"The staff at 2 x 10 Nails & Spa are some of the most talented technicians I have ever come across. They take great care in their work and truly care about their clients."</h1>
                        <br />
                        <h2>Amanda Rodriguez</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                    <div className='review border-green'>
                        <h1 className='color1'>"I had a great experience at 2 x 10 Nails & Spa. The salon is modern and chic, and the staff made me feel right at home. I highly recommend them for all your nail care needs."</h1>
                        <br />
                        <h2>Jessica Nguyen</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                    <div className='review border-green'>
                        <h1 className='color1'>"I recently visited 2 x 10 Nails & Spa for a pedicure and was blown away by the level of service I received. The staff was so friendly and attentive, and my feet have never looked or felt better."</h1>
                        <br />
                        <h2>Taylor Thompson</h2>
                        <p className='color3'>Chicago, Illinois</p>
                    </div>
                </div>
            </div>

            {/* FOOTER */}
            <div className='bottom'>
                <Footer />
            </div>
        </div>
    )
}
